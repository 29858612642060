export const treeData = {
    r: {
        name: "",
        children: [
            {
                name: "股东",
                
            }, {
                name: "供应商",
            },
            {
                name: "合伙人",
            }
        ]
    },
    l: {
        name: "",
        children: [
            {
                name: "合伙人",
            },
            {
                name: "合作公司",
            },
            {
                name: "合伙人",
            },
            {
                name: "合作公司",
            }
        ]
    }
}