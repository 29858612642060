<template>
    <div class="custom_tree_container">
        <ul>
            <li v-for="(item, index) in tabArr" :key="index" :class="[active === index ? 'active_item' : '']" @click="changeItem(index)">{{ item }}</li>
        </ul>
        <div v-show="active === 0">
            <div class="holder"> 
                <div>
                    <div>
                        <h1>width: 960, height: 400</h1>
                        <p>svg的宽高，默认960*400</p>
                    </div>
                    <span @click="play1">查看</span>
                </div>
                <div>
                    <div>
                        <h1>marginTop: 40, marginRight: 90, marginBottom: 40, marginLeft: 90</h1>
                        <p>svg图表偏移量</p>
                    </div>
                    <span  @click="play2">查看</span>
                </div>
                <div>
                    <div>
                        <h1>multiple: 1</h1>
                        <p>用于控制起始点位置：当值为1时，从主节点的边缘出发；当值为2时，从主节点中点出发</p>
                    </div>
                    <span  @click="play3">查看</span>
                </div>
                <div>
                    <div>
                        <h1>duration</h1>
                        <p>动画持续时长</p>
                    </div>
                    <span  @click="play4">查看</span>
                </div>
                <div>
                    <div>
                        <h1>delay</h1>
                        <p>动画等待时长</p>
                    </div>
                    <span @click="play5">查看</span>
                </div>
                <div>
                    <div>
                        <h1>ease <a href="https://github.com/d3/d3-ease/tree/v3.0.1" target="_blank">查看easing</a></h1>
                        <p> 加速减速，可选项：easeQuad,easeCubic,easeExp,easeCircle,easeBack。除了easeBack外，其余的加速度强烈程度依次增加</p>
                    </div>
                    <span  @click="play6">查看</span>
                </div>
                <div>
                    <div>
                        <h1>img</h1>
                        <p>设置根节点图片</p>
                    </div>
                    <span  @click="play7">查看</span>
                </div>
                <div>
                    <div>
                        <h1>lineWidth: 300</h1>
                        <p>设置线的长度, 默认300px</p>
                    </div>
                    <span  @click="play8">查看</span>
                </div>
                <div>
                    <div>
                        <h1>nodeLength： 100</h1>
                        <p>设置主节点宽高, 默认100px</p>
                    </div>
                    <span  @click="play9">查看</span>
                </div>
                <div>
                    <div>
                        <h1>branchNodeWidth: 200, branchNodeHeight: 100</h1>
                        <p>设置分支节点的宽高，默认200*100</p>
                    </div>
                    <span  @click="play10">查看</span>
                </div>
            </div>
            <CustomTree ref="customTree" id="customTree1" />

        </div>
        <div v-show="active === 1">
            <div class="holder"> 
                <div>
                    <div>
                        <h1>width: 800, height: 800</h1>
                        <p>svg的宽高，默认800*800</p>
                    </div>
                    <span @click="play11">查看</span>
                </div>
                <div>
                    <div>
                        <h1>marginTop: 0, marginRight: 0, marginBottom: 0, marginLeft: 0</h1>
                        <p>svg图表偏移量</p>
                    </div>
                    <span  @click="play12">查看</span>
                </div>
                <div>
                    <div>
                        <h1>multiple: 2</h1>
                        <p>用于控制起始点位置：当值为2时，从主节点的边缘出发；当值为1时，从主节点中点出发</p>
                    </div>
                    <span  @click="play13">查看</span>
                </div>
                <div>
                    <div>
                        <h1>duration, 默认1000</h1>
                        <p>动画持续时长</p>
                    </div>
                    <span  @click="play14">查看</span>
                </div>
                <div>
                    <div>
                        <h1>delay， 默认500</h1>
                        <p>动画等待时长</p>
                    </div>
                    <span @click="play15">查看</span>
                </div>
                <div>
                    <div>
                        <h1>ease <a href="https://github.com/d3/d3-ease/tree/v3.0.1" target="_blank">查看easing</a>, 默认easeBack</h1>
                        <p> 加速减速，可选项：easeQuad,easeCubic,easeExp,easeCircle,easeBack。除了easeBack外，其余的加速度强烈程度依次增加</p>
                    </div>
                    <span  @click="play16">查看</span>
                </div>
                <div>
                    <div>
                        <h1>img</h1>
                        <p>设置根节点图片</p>
                    </div>
                    <span  @click="play17">查看</span>
                </div>
                <div>
                    <div>
                        <h1>bottomNode</h1>
                        <p>主节点下方节点显示内容</p>
                    </div>
                    <span  @click="play18">查看</span>
                </div>
                <div>
                    <div>
                        <h1>nodeSpaceBetween, 默认100</h1>
                        <p>节点间的间距</p>
                    </div>
                    <span  @click="play19">查看</span>
                </div>
                <div>
                    <div>
                        <h1>lineLength: 200</h1>
                        <p>设置线的长度, 默认200px</p>
                    </div>
                    <span  @click="play20">查看</span>
                </div>
                <div>
                    <div>
                        <h1>rootNodeLength： 100</h1>
                        <p>设置主节点宽高, 默认100px</p>
                    </div>
                    <span  @click="play21">查看</span>
                </div>
                <div>
                    <div>
                        <h1>branchNodeWidth: 220, branchNodeHeight: 100</h1>
                        <p>设置分支节点的宽高，默认220*100</p>
                    </div>
                    <span  @click="play22">查看</span>
                </div>
            </div>
            <TwoflanksTree ref="twoflanks" id="twoflanks" />
        </div>
    </div>
</template>
<script>
import TwoflanksTree from '../../../../components/customTree/twoflanks.vue';
import CustomTree from '../../../../components/customTree';
import { treeData } from './treeData'
import img from './组 2935.svg'
export default {
     data: function() {
        return {
            active: 0,
            tabArr: ['CustomTree', 'TwoflanksTree'],
            data: {
                name: '',
                children: [
                    { 
                        name: "投研+投顾系统；量化系统；营销系 统咨询、知识图谱、算法",
                        children: [
                            { name: "智能决策工具+智能投资助手" },
                        ]
                    },
                    { 
                        name: "弹指文、一键分发、互联网运营服务",
                        children: [
                            { name: "智能内容创作+分发+互联网运营服务" },
                        ]
                    },
                    { 
                        name: "智能化平台架构及搭建",
                        children: [
                            { name: "证券服务化平台+大数据平台+行情云服务" },
                        ]
                    }
                ]
            }
        }
    },
    components: {
        TwoflanksTree,
        CustomTree
    },
    mounted() {
        this.$refs.customTree.CustomTreeChart(this.data, {})
    }, 
    methods: {
        changeItem(index) {
            this.active = index
            if (index === 0) {
                this.$refs.customTree.CustomTreeChart(this.data, {})
            } else if (index === 1) {
                this.$refs.twoflanks.init(treeData, {})
            }
        },
        play1() {
            this.$refs.customTree.CustomTreeChart(this.data, {
                width: 970,
                height: 770,
            })
        },
        play2() {
            this.$refs.customTree.CustomTreeChart(this.data, {
                marginLeft: 90,
                marginRight: 90,
                marginTop: 100,
                marginBottom: 50,
            })
        },
        play3() {
            this.$refs.customTree.CustomTreeChart(this.data, {
                multiple: 2,
            })
        },
        play4() {
            this.$refs.customTree.CustomTreeChart(this.data, {
                duration: 2000,
            })
        },
        play5() {
            this.$refs.customTree.CustomTreeChart(this.data, {
                delay: 2000,
            })
        },
        play6() {
            this.$refs.customTree.CustomTreeChart(this.data, {
                ease: 'easeQuad',
            })
        },
        play7() {
            this.$refs.customTree.CustomTreeChart(this.data, {
                img,
            })
        },
        play8() {
            this.$refs.customTree.CustomTreeChart(this.data, {
                lineWidth: 250,
            })
        },
        play9() {
            this.$refs.customTree.CustomTreeChart(this.data, {
                nodeLength: 150,
            })
        },
        play10() {
            this.$refs.customTree.CustomTreeChart(this.data, {
                branchNodeWidth: 260,
                branchNodeHeight: 200
            })
        },


        play11() {
            this.$refs.twoflanks.init(treeData, {
                width: 700,
                height: 700,
            })
        },
        play12() {
            this.$refs.twoflanks.init(treeData, {
                marginTop: 10,
                marginRight: 10,
                marginBottom: 10,
                marginLeft: 10,
            })
        },
        play13() {
            this.$refs.twoflanks.init(treeData, {
                multiple: 4,
            })
        },
        play14() {
            this.$refs.twoflanks.init(treeData, {
                duration: 2000,
            })
        },
        play15() {
            this.$refs.twoflanks.init(treeData, {
                delay: 2000,
            })
        },
        play16() {
            this.$refs.twoflanks.init(treeData, {
                ease: 'easeQuad',
            })
        },
        play17() {
            this.$refs.twoflanks.init(treeData, {
                img,
            })
        },
        play18() {
            this.$refs.twoflanks.init(treeData, {
                bottomNode: 'demo node',
            })
        },
        play19() {
            this.$refs.twoflanks.init(treeData, {
                nodeSpaceBetween: 200,
            })
        },
        play20() {
            this.$refs.twoflanks.init(treeData, {
                lineLength: 150,
            })
        },
        play21() {
            this.$refs.twoflanks.init(treeData, {
                rootNodeLength: 200,
            })
        },
        play22() {
            this.$refs.twoflanks.init(treeData, {
                branchNodeWidth: 100,
                branchNodeHeight: 100,
            })
        },
    },
}
</script>
<style lang="less" scoped>
.custom_tree_container {
    padding: 8px 0 0 12px;
    > ul {
        display: flex;
        > li {
            font-size: 24px;
            font-weight: bold;
            margin-right: 36px;
            cursor: pointer;
            &:hover {
                color: #fe9805;
            }
        }
        .active_item {
            color: #fe9805;
        }
    }
    > div {
        display: flex;
        justify-content: space-between;
        margin-top: 36px;
        .holder {
            > div {
                display: flex;
                align-items: center;
                h1 {
                    font-size: 18px;
                    color: #9c27b0;
                    margin-bottom: 10px;
                    span {
                        color: #fff;
                        background: #f00;
                        padding: 8px;
                        border-radius: 5px;
                        margin-right: 10px;
                    }
                }
                p {
                    margin-bottom: 24px;
                    color: #555;
                }
                > span {
                    border-radius: 4px;
                    cursor: pointer;
                    font-size: 16px;
                    height: 24px;
                    padding: 4px 8px;
                    background: #447746;
                    color: #fff;
                    margin-left: 56px;
                }
            }
        }
    }
}
</style>